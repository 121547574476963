import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import workflowEvent from '../../workflowEvent';
import Employee from '../../../admin/employee';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'addDelegation',
  components: {
    ModelSelect,
    DatePicker,
    workflowEvent,
    Employee
  },
  props: { addDelegation: Boolean },
  data() {
    return {
      showEmployeeModal: false,
      showEventsModal: false,
      vsetCode: null,
      showValueSetModal: null,
      unsubscribe: null,
      deleListIndex: null,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      legalEntityList: [],
      selectedLegalEntity: {
        value: null,
        text: null
      },
      delegationFrom: {
        delegated_from: null,
        employee_num: null,
        employee_name: null,
        event_name: null,
        delegation_details: []
      },
      delegationDetailFeilds: [
        {
          key: 'wf_event_name',
          label: 'Event'
        },
        {
          key: 'delegated_employee_name',
          label: 'Emp Name'
        },
        {
          key: 'start_date_end_date',
          label: 'Start Date - End Date'
        },
       
      ]
    };
  },
  mounted() {
    this.eventBus.$on('groupDetail', delegationDetail => {
      this.getDelegationDetail(delegationDetail);
    });
    if (this.addDelegation) {
      this.getDefaultEmployee();
      this.getEventList();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditDelegation();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {

          this.eventBus.$emit('commonUpload', { id: this.delegationFrom.delegated_from});

        }
      }
    });
  },
  methods: {
    addEditDelegation() {
      const deleDetail = this.delegationFrom.delegation_details
        .map(detail => {
          return {
            delegated_id: detail.delegated_id,
            delegated_to: detail.delegated_to,
            event_id: detail.wf_event_id,
            start_date: commonHelper.formattedDate(detail.start_date_end_date[0]),
            end_date: commonHelper.formattedDate(detail.start_date_end_date[1]),
          
          };
        })
        .filter(detail => detail.delegated_to !== null);
      const payload = {
        delegated_from: this.delegationFrom.delegated_from,
        delegation_details: deleDetail
      };
      this.loader = true;
      this.$store
        .dispatch('workflow/addEditDelegation', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.$emit('updateList');
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getDelegationDetail(delegationDetail) {
      this.delegationFrom.employee_num = delegationDetail.employee_num;
      this.delegationFrom.employee_name = delegationDetail.employee_name;
      this.delegationFrom.delegated_from = delegationDetail.from_emp_id;
      this.delegationFrom.delegation_details = [];
      this.delegationFrom.delegation_details.push({
        delegated_id: delegationDetail.wf_delegation_id,
        delegated_to: delegationDetail.delegated_employee_id,
        wf_event_id: delegationDetail.wf_event_id,
        wf_event_name: delegationDetail.wf_event_name,
        delegated_employee_name: delegationDetail.delegated_employee_name,
        start_date: new Date(delegationDetail.start_date),
        end_date: new Date(delegationDetail.end_date),
        start_date_end_date: [new Date(delegationDetail.start_date), new Date(delegationDetail.end_date)]
      });
    },
    getDefaultEmployee() {
      this.$store
        .dispatch('workflow/getDelegationDefaultEmpList')
        .then(response => {
          if (response.status === 200) {
            this.delegationFrom.employee_num = response.data.data.employee_num;
            this.delegationFrom.employee_name =
              response.data.data.employee_name;
            this.delegationFrom.delegated_from = response.data.data.employee_id;
          }
        });
    },
    getEventList() {
      this.loader = true;
      const payload = {
        _page: 0,
        _limit: 10,
        event_name: this.delegationFrom.event_name
      };
      this.$store
        .dispatch('workflow/getWorkflowEventList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page.map(detail => {
              return {
                delegated_id: 0,
                delegated_to: null,
                wf_event_id: detail.event_id,
                wf_event_name: detail.event_name,
                delegated_employee_name: null,
                start_date: null,
                end_date: null
              };
            });
            this.delegationFrom.delegation_details = result;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideEventModal(flag) {
      this.showEventsModal = flag;
    },
    selectedworkflowEvent(item) {
      this.delegationFrom.event_name = item.event_name;
      this.getEventList();
      this.showEventsModal = false;
    },
    clearSearch() {
      this.delegationFrom.event_name = null;
      this.getEventList();
    },
    showHideEmpModal(flag, index) {
      if (flag) {
        this.deleListIndex = index;
        this.showEmployeeModal = true;
      } else {
        this.showEmployeeModal = false;
      }
    },
    selectedEmployee(item) {
      this.delegationFrom.delegation_details[
        this.deleListIndex
      ].delegated_employee_name = item.name;
      this.delegationFrom.delegation_details[this.deleListIndex].delegated_to =
        item.employee_id;
      this.showEmployeeModal = false;
    },
    rowSelected() {}
  },
  beforeDestroy() {
    this.eventBus.$off('groupDetail');
    this.unsubscribe();
  }
};
