import commonHelper from '@/app/utility/common.helper.utility';
import addDelegation from './addDelegation';
import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
import Employee from '../../admin/employee';
export default {
  name: 'applicationCriteria',
  components: {
    addDelegation,
    ModelSelect,
    DatePicker,
    Employee
  },
  watch: {
    currentPage: function() {
      this.getDelegationList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getDelegationList();
    }
  },
  data() {
    return {
      searchForm: {
        startDate: null,
        endDate: null,
        empName: null,
        start_date_end_date:[],
        empId: null
      },
      selectedEvent: {
        value: null,
        text: null
      },
      eventList: [],
      showEmployeeModal: false,
      addDelegation: false,
      appCriteria: null,
      appVersion: null,
      active: null,
      timeOut: null,
      showAddGroup: false,
      unsubscribe: null,
      ruleName: null,
      loader: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      valueSetName: null,
      currentPage: 1,
      totalRows: null,
      delegationList: [],
      delegationFeilds: [
        {
          key: 'wf_event_name',
          label: 'Event'
        },
        {
          key: 'delegated_employee_name',
          label: 'Deleagted Employee'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ]
    };
  },
  mounted() {
    this.getDelegationList();
    this.getEventList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddGroup = true;
          this.addDelegation = true;
        }
        if (actionName === 'download' && !this.showAddGroup) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'workflow/getDelegationList',
            'workflow-Delegation',
            () => (this.loader = false)
          );
        }  
      }
    });
  },
  methods: {
    getDelegationList() {
      this.loader = true;
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        event_id: this.selectedEvent.value,
        emp_id: this.searchForm.empId,
        start_date: commonHelper.formattedDate(this.searchForm.start_date_end_date[0]),
        end_date: commonHelper.formattedDate(this.searchForm.start_date_end_date[1]),
      };
      this.$store
        .dispatch('workflow/getDelegationList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.delegationList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getEventList() {
      this.loader = true;
      const payload = {
        _page: 0,
        _limit: 10,
        event_name: null
      };
      this.$store
        .dispatch('workflow/getWorkflowEventList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page.map(detail => {
              return {
                text: detail.event_name,
                value: detail.event_id
              };
            });
            this.eventList = result;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clear() {
      this.selectedEvent = {
        value: null,
        text: null
      };
      this.searchForm.empName = null;
      this.searchForm.empId = null;
      this.searchForm.start_date_end_date = [];
      this.getDelegationList();
    },
    hideGroupModal() {
      this.showAddGroup = false;
    },
    rowSelected(item) {
      this.addDelegation = false;
      this.showAddGroup = true;
      this.timeOut = setTimeout(() => {
        this.eventBus.$emit('groupDetail', item);
      }, 0);
    },
    showHideEmpModal(flag) {
      this.showEmployeeModal = flag;
    },
    selectedEmployee(item) {
      this.searchForm.empName = item.name;
      this.searchForm.empId = item.employee_id;
      this.showEmployeeModal = false;
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
    this.unsubscribe();
  }
};
